export interface FeatureFlag {
  key: string;
  allowed: boolean;
}

// keep this up to date with flags coming from LaunchDarkly
export enum FeatureFlags {
  /**
   * Enables AI
   */
  AI = 'ai',

  /**
   * Enables Chatbot
   */
  Chatbot = 'bolt-ai-chatbot',

  /**
   * Enables Bolt Copilot
   */
  Copilot = 'bolt-ai-copilot',

  /**
   * Enables Bolt Copilot for Campaigns
   */
  CopilotCampaigns = 'bolt-ai-copilot-campaigns',

  /**
   * Enables Bolt Copilot for Appointments
   */
  CopilotAppointments = 'bolt-ai-copilot-appointments',

  /**
   * Enables Bolt Copilot for Search (Bolt Search functionality in Copilot)
   */
  CopilotSearch = 'bolt-ai-copilot-search',

  /**
   * Enables Bolt Copilot for Pages
   */
  CopilotPages = 'bolt-ai-copilot-pages',

  /**
   * Shows Copilot Preview Indicator
   */
  GlobalCopilotPreviewIndicator = 'global-copilot-preview-indicator',

  /**
   * Enables ability for global copilot to retrieve contact field information
   */
  GlobalCopilotFieldRetreival = 'global-copilot-field-retrieval',

  /**
   * Allow Changing Bolt Bot Type
   */
  BoltChatbotTypePicker = 'bolt-ai-chatbot-type-picker',

  /**
   * BoltBot Preview Instance
   */
  BoltBotPreviewInstance = 'boltbot-preview-instance',

  /**
   * Allow BoltBot Academic Explorer Skill
   */
  BoltBotAcademicExplorerSkill = 'bolt-chatbot-academic-explorer-skill',

  /**
   * Allow BoltBot Course Catalog Skill
   */
  BoltBotCourseCatalogSkill = 'bolt-chatbot-course-catalog-skill',

  /**
   * Enables smart segment filters in segment builder
   */
  SmartSegmentFilters = 'smart-segments-filters',

  /**
   * Enables Export Conversations Analytics
   */
  ExportConversationsAnalytics = 'export-conversations-analytics',

  /**
   * Enables Ignite To Our Clients
   */
  IgniteClient = 'ignite-client',

  /**
   * Brand Writing Style Guide
   */
  SettingsBrandWritingStyleGuide = 'settings-branding-writing-style',

  /**
   * Inboxes
   */
  Inboxes = 'inboxes',
  InboxesGmail = 'inboxes-gmail',
  InboxesOutlook = 'inboxes-outlook',

  /**
   * In App Calling
   */
  InAppCalling = 'in-app-calling',

  /**
   * Push Notifications
   */
  PushNotifications = 'push-notifications',

  /**
   * Shows copilot button to translate a message
   */
  CopilotTranslateMessage = 'copilot-conversation-message-translation',

  /**
   * Enables Courses
   */
  CoursesDataSource = 'courses-data-source',
  /**
   * Enables ability to negate a metafilter item
   */
  SegmentFilterItemNegation = 'segment-filter-item-negation',
  /**
   * Will optimize segment builder filters loading by loading them on demand
   */
  SegmentBuilderLazyLoadedFilters = 'segment-builder-lazy-loaded-filters',
  /**
   *  Enables ability to have organization profile type
   */
  ProfileTypeOrganization = 'profile-type-organization',

  /**
   *  Enables territory evaluation
   */
  TerritoriesEvaluation = 'territories-evaluation',

  /**
   * Enables ability to select when should a communication be sent from workflows
   */
  WorkflowsSendAtPreferredTime = 'workflows-send-communication-at-preferred-time',

  /**
   * Enables workflows assignment behavior
   */
  WorkflowsAssignmentBehavior = 'workflows-assignment-behavior',

  /**
   * Enabled ability to see archived notification content
   */
  ArchivedNotificationContent = 'archived-notification-content',

  /**
   * Enables student assistants
   */
  StudentAssistants = 'student-assistants',

  /**
   * Enables voice calling
   */
  VoiceCall = 'voice-call',

  /**
   * Enables ability to see the direct link to the messenger
   */
  MessengerDirectLinkAccess = 'messenger-direct-link-access',

  /**
   * Enables ability to expand messenger
   */
  MessengerExpand = 'messenger-expand',

  /*
   * Enabled integrations log
   */
  IntegrationsLogs = 'integrations-logs',

  /**
   * Enables ability to use subpages
   */
  PagesSubpages = 'pages-subpages',

  /**
   * Conversations Audio Part
   */
  ConversationsAudioPart = 'conversations-audio-part',

  /**
   * Assitants Audio Response
   */
  AssitantsAudioResponse = 'assistants-audio-response',

  /**
   * MultiChannel Anonymous Conversations
   */
  MultiChannelAnonymousConversations = 'multi-channel-anonymous-conversations',
  /**
   * Private Notes
   */
  PrivateNotes = 'private-notes',

  /**
   * Conversations Visibility Groups Notifications
   */
  ConversationsVisbilityGroupsNotifications = 'conversations-visibility-groups-notifications',
  /**
   * Insights Dashboards
   */
  InsightsTasksPerformanceDashboard = 'insights-tasks-dashboard',
  InsightsCampaignsDashboard = 'insights-campaigns-dashboard',
  InsightsEventsDashboard = 'insights-events-dashboard',
  InsightsFunnelDashboard = 'insights-funnel-dashboard',
  InsightsSourcesDashboard = 'insights-sources-dashboard',
  InsightsConversationsDashboard = 'insights-conversations-dashboard',
  InsightsWebActivitiesDashboard = 'insights-web-activities-dashboard',
  InsightsManagementDashboard = 'insights-management-dashboard',
  InsightsAppointmentsDashboard = 'insights-appointments-dashboard',
  InsightsDataHygieneDashboard = 'insights-data-hygiene-dashboard',
  InsightsPaymentsDashboard = 'insights-payments-dashboard',
  InsightsApplicationsDashboard = 'insights-applications-dashboard',
  InsightsDiscoveryDashboard = 'insights-discovery-dashboard',

  InsightsBoltBar = 'insights-bolt-bar',
  InsightsTagRowLevelSecurity = 'insights-tag-row-level-security',

  /**
   * Enables Terms of Service
   */
  Dashboard451TermsOfService = 'dashboard451-tos',

  /**
   * Enables tasks templates
   */
  Dashboard451TasksTemplates = 'dashboard451-tasks-templates',

  /**
   * Knowledge base features
   */
  Dashboard451KnowledgeFileUpload = 'dashboard451-knowledge-file-upload',
  Dashboard451KnowledgeYoutubeChannel = 'dashboard451-knowledge-youtube-channel',
  Dashboard451KnowledgeYoutubeVideo = 'dashboard451-knowledge-youtube-video',
  Dashboard451KnowledgeRssFeed = 'dashboard451-knowledge-rss-feed',

  // Dashboard modules
  Dashboard451Insights = 'dashboard451-insights',
  Dashboard451Sites = 'dashboard451-sites',
  Dashboard451RequestInformation = 'dashboard451-request-information',
  Dashboard451SupplementalForms = 'dashboard451-supplemental-forms',
  Dashboard451SubmissionPreventionRules = 'dashboard451-submission-prevention-rules',
  Dashboard451PaymentRules = 'dashboard451-payment-rules',
  Dashboard451Applications = 'dashboard451-applications',
  Dashboard451Campaigns = 'dashboard451-campaigns',
  Dashboard451CampaignRecipients = 'dashboard451-campaign-recipients',
  Dashboard451Workflows = 'dashboard451-workflows',
  Dashboard451Webhooks = 'dashboard451-webhooks',
  Dashboard451Journeys = 'dashboard451-journeys',
  Dashboard451Appointments = 'dashboard451-appointments',
  Dashboard451Events = 'dashboard451-events',
  Dashboard451Conversations = 'dashboard451-conversations',
  Dashboard451ConversationsWhatsAppChannel = 'dashboard451-conversations-whats-app-channel',
  Dashboard451ConversationsPrivateConversations = 'dashboard451-conversations-private-conversations',
  Dashboard451Pages = 'dashboard451-pages',
  Dashboard451Forms = 'dashboard451-forms',
  Dashboard451Microsites = 'dashboard451-microsites',
  Dashboard451Decision = 'dashboard451-decision',
  Dashboard451MediaManager = 'dashboard451-media-manager',
  Dashboard451DocumentsBin = 'dashboard451-documents-bin',
  Dashboard451DocumentTypes = 'dashboard451-document-types',
  Dashboard451FieldManagement = 'dashboard451-field-management',
  Dashboard451Deduplication = 'dashboard451-deduplication',
  Dashboard451DataSources = 'dashboard451-data-sources',
  Dashboard451SourceCodes = 'dashboard451-source-codes',
  Dashboard451Territories = 'dashboard451-territories',
  Dashboard451Organizations = 'dashboard451-organizations',
  Dashboard451People = 'dashboard451-people',
  Dashboard451Labels = 'dashboard451-labels',
  Dashboard451Segments = 'dashboard451-segments',
  Dashboard451Tasks = 'dashboard451-tasks',
  Dashboard451ActivityTypes = 'dashboard451-activity-types',
  Dashboard451Profile = 'dashboard451-profile',
  Dashboard451ManageUsers = 'dashboard451-manage-users',
  Dashboard451Integrations = 'dashboard451-integrations',
  Dashboard451ProfileTemplates = 'dashboard451-profile-templates',
  Dashboard451AdminProfile = 'dashboard451-admin-profile',
  Dashboard451Packs = 'dashboard451-packs',
  Dashboard451Surveys = 'dashboard451-surveys',
  Dashboard451Covideo = 'dashboard451-covideo',
  Dashboard451Preview = 'dashboard451-preview',
  Dashboard451ImportExport = 'dashboard451-import-export',
  Dashboard451ImportExportV2 = 'dashboard451-import-export-v2',
  Dashboard451Knowledge = 'dashboard451-knowledge',
  Dashboard451Start = 'dashboard451-start',
  Dashboard451GlobalCopilot = 'dashboard451-global-copilot',
  Dashboard451GlobalCopilotShortcuts = 'dashboard451-global-copilot-shortcuts',
  Dashboard451Settings = 'dashboard451-settings',
  Dashboard451PortalSettings = 'dashboard451-portal-settings',
  Dashboard451Overview = 'dashboard451-overview',
  Dashboard451VisibilityGroups = 'dashboard451-visibility-groups',
  Dashboard451BoltSearch = 'dashboard451-bolt-search',
  Dashboard451NetworkRoles = 'dashboard451-network-roles',
  Dashboard451PermissionGroups = 'dashboard451-permission-groups',
  Dashboard451Teams = 'dashboard451-teams',
  Dashboard451Activity = 'dashboard451-activity',
  Dashboard451UserAssignments = 'dashboard451-user-assignments',
  Dashboard451UserRestrictions = 'dashboard451-user-restrictions',
  Dashboard451PreferredStartPage = 'dashboard451-preferred-start-page',
  Dashboard451ExternalAuthenticationSettings = 'dashboard451-external-authentication-settings',
  Dashboard451ExternalUsersSSO = 'dashboard451-external-users-sso',
  Dashboard451Courses = 'dashboard451-courses',
  Dashboard451MeteredPricing = 'dashboard451-metered-pricing',

  /** # TEMPORARY FLAGS # */
  CanAccessJourneysSystemTemplates = 'journeys-system-templates-access',
  CanAccessWFFormulaTypeToSetUserProperty = 'workflows-actions-set-user-property-via-formula',
  CanAccessWFAiPromptTypeToSetUserProperty = 'workflows-actions-set-user-property-via-ai-prompt',
  CanAccessEmailBuilderHtmlAndVideoBlock = 'email-builder-html-video-blocks',
  CanAccessEmailBuilderAiTextPromptBlock = 'email-builder-ai-text-prompt-block',
  CanAccessCampaignsVisibilityByTags = 'campaigns-visibility-by-tags',
  CanAccessCustomerBillingSection = 'customer-billing-enabled',
  ShowFormTypePicker = 'show-form-type',
  LiveSupportSeats = 'enable-live-support-seats',
  CampaignsUtmSettings = 'campaigns-utm-settings',

  /**
   * Conversations Schedule Messenger
   */
  ConversationsScheduleMessenger = 'conversations-schedule-messenger',

  /**
   * Email Builder Custom Components
   */
  EmailBuilderCustomComponents = 'email-builder-custom-components',

  /**
   * Authenticated verified forms
   */
  AuthenticatedVerifiedForms = 'authenticated-verified-forms',

  /**
   * Page451 Custom Components
   */
  Page451CustomComponents = 'page451-custom-components',

  /**
   * Message Builder
   */
  MessageBuilderShouldUseEmailBuilder = 'message-builder-should-use-email-builder',

  /**
   * Event Self Check-In
   */
  Dashboard451EventsSelfCheckIn = 'dashboard451-events-self-check-in',

  /**
   * Network
   */
  UserNetwork = 'user-network',

  /**
   * Contact Tasks
   */
  ContactTasks = 'contact-tasks',

  /**
   * Recaptcha toggle for forms
   */
  RecaptchaFormsToggle = 'recaptcha-forms-toggle',

  /**
   * Conversations Moderation
   */
  ConversationsModeration = 'conversations-moderation',

  /**
   * Conversations Compliance Check
   */
  ConversationsComplianceCheck = 'conversations-compliance-check',

  /**
   * SFTP Management
   */
  Dashboard451SFTPManagement = 'dashboard451-sftp-management',

  /**
   * Private Phone Call
   */
  PrivatePhoneCall = 'private-phone-call',

  /**
   * AI Phone Call
   */
  AiPhoneCall = 'ai-phone-call',

  /**
   * Payment Gateways
   */
  Dashboard451PaymentGateways = 'dashboard451-payment-gateways',

  /**
   * Conditional Criteria Settings
   */
  CanAccessDecisionCriteriaConditionalSettings = 'decision-criteria-conditional-settings',

  /**
   * App Fraud Detection
   */
  Dashboard451AppFraudDetection = 'dashboard451-app-fraud-detection',

  /**
   * Knowledge Folders
   */
  CanManageArticlesFolders = 'knowledge-folders',

  /** *
   * Student Hub AI Feed
   */
  StudentHubAiFeed = 'student-hub-ai-feed',

  /**
   * Bolt Discovery Gemini 2.0
   */
  BoltDiscoveryGemini20 = 'bolt-discovery-gemini-2.0',

  /**
   * Proactive Assistants
   */
  ProactiveAssistants = 'proactive-assistants',

  /**
   * A2P Campaign Setup Required For Sending SMS
   */
  A2PCampaignSetupRequiredForSendingSms = 'a2p-campaign-setup-required-for-sending-sms',

  /**
   * A2P Campaign Registration
   */
  Dashboard451TwilioSelfOnboarding = 'twilio-self-onboarding',

  /**
   * Conversations Inbox From Rewrite
   * Temporary flag for testing
   */
  ConversationsInboxFromRewrite = 'conversations-inbox-from-rewrite',

  /**
   * AI App Reader
   */
  Dashboard451AiAppReader = 'dashboard451-ai-app-reader',

  /**
   * Campaign Writer GPT 4.5
   */
  CampaignWriterGpt45 = 'campaign-writer-gpt-4.5',

  /**
   * Scheduled Messages
   */
  Dashboard451ScheduledMessages = 'dashboard451-scheduled-messages',

  /**
   * Canvas LMS
   */
  CanvasLms = 'canvas-lms-integration',

  /**
   * Brightspace LMS
   */
  BrightspaceLms = 'brightspace-lms-integration',

  /**
   * Outbound Phone Call Research
   */
  OutboundPhoneCallResearch = 'outbound-phone-call-research'
}
