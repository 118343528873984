import { FormsApi } from '@element451-libs/models451';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { invert, isNumber } from 'lodash';
import { ensureState, optimistic, OptimisticState } from 'redux-optimistic-ui';
import { ACCOUNT_ACTIONS, AccountAction } from '../account/account.actions';
import { selectApp } from '../app.feature';
import { EDIT_PROFILE_ACTIONS, EditProfileAction } from '../edit-profile';
import { STEPS_ACTIONS, StepsAction } from '../steps/steps.actions';
import {
  getFileFieldData,
  getUpdatedValue,
  removeRepeaterItem,
  sectionFormUpdate,
  updateRepeaterItem,
  updateUserData,
  userDataToFormData
} from './helpers';
import { USER_DATA_ACTIONS, UserDataActions } from './user-data.actions';
import { UserDataState } from './user-data.models';

export type OptimisticUserDataState = OptimisticState<UserDataState>;

export const initialState: UserDataState = {
  data: {},
  registrationId: null,
  fieldSlugNameMappings: null,
  applicationGuid: null
};

export function userDataReducer(
  state: UserDataState = initialState,
  action: UserDataActions | StepsAction | EditProfileAction | AccountAction
): UserDataState {
  switch (action.type) {
    case USER_DATA_ACTIONS.LOAD_USER_DATA_REQUEST: {
      const registrationId = action.payload;
      const loaded = isLoaded(state, registrationId);
      return loaded ? state : { ...state, registrationId: null, data: {} };
    }

    case USER_DATA_ACTIONS.LOAD_USER_DATA_SUCCESS: {
      const { registrationId, data } = action.payload;
      return { ...state, data, registrationId };
    }

    case ACCOUNT_ACTIONS.SIGN_OUT:
      return initialState;

    case STEPS_ACTIONS.SUBMIT_SECTION_FORM_REQUEST: {
      const fieldsToUpdate = [
        ...action.payload.data.fields,
        ...action.payload.data.unset
      ];
      return sectionFormUpdate(fieldsToUpdate, state);
    }

    case STEPS_ACTIONS.SUBMIT_SECTION_FORM_SUCCESS: {
      const responseData = action.payload.response.info[0];
      // means it is a repeater and we should attach index weight with the update
      if (isNumber(responseData?.weight)) {
        const fields = action.payload.updatedFields;
        fields[0].weight = responseData.weight;
        return sectionFormUpdate(fields, state);
      }

      return state;
    }

    case STEPS_ACTIONS.ADD_FILE: {
      try {
        const { file, fieldName, fields } = action.payload;
        const fileFieldData = getFileFieldData(fields, fieldName, state);
        if (!fileFieldData) return state;

        const newValue = fileFieldData.isMultiType
          ? { files: [...fileFieldData.value, file] }
          : file;

        const formEntry = [{ name: fieldName, value: newValue }];
        return updateUserData(formEntry, fields, state);
      } catch (err) {
        console.error(err);
      }
      return state;
    }

    case STEPS_ACTIONS.REMOVE_FILE_SUCCESS: {
      try {
        const { fields, filename, fileGuid, fieldName } = action.payload;
        const fileFieldData = getFileFieldData(fields, fieldName, state);
        if (!fileFieldData) return state;

        const newValue = fileFieldData.isMultiType
          ? {
              files: fileFieldData.value.filter(file => file.guid !== fileGuid)
            }
          : null;

        const formEntry = [{ name: fieldName, value: newValue }];
        return updateUserData(formEntry, fields, state);
      } catch (err) {
        console.error(err);
      }

      return state;
    }

    case STEPS_ACTIONS.DELETE_REPEATER_ITEM_SUCCESS: {
      const { slug, weight } = action.payload;
      return removeRepeaterItem(slug, weight, state);
    }

    case STEPS_ACTIONS.SAVE_REPEATER_ITEM_SUCCESS: {
      const responseData = action.payload.response.info[0];
      const updates = getUpdatedValue(responseData);
      updates.weight = responseData.weight || action.payload.weight;

      const slug = action.payload.fields[0].slug;

      // added/updated repeater item values, with weight, in the userData format
      return updateRepeaterItem(updates, slug, state);
    }

    case EDIT_PROFILE_ACTIONS.GET_PROFILE_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload
        }
      };
    }

    case EDIT_PROFILE_ACTIONS.UPDATE_PROFILE_SUCCESS: {
      const { fields, data } = action.payload;
      return updateUserData(data, fields, state);
    }

    case USER_DATA_ACTIONS.LOAD_FIELD_SLUG_NAME_MAPPINGS_REQUEST: {
      return {
        ...state,
        fieldSlugNameMappings: null,
        applicationGuid: action.payload
      };
    }

    case USER_DATA_ACTIONS.LOAD_FIELD_SLUG_NAME_MAPPINGS_SUCCESS: {
      return {
        ...state,
        fieldSlugNameMappings: action.payload
      };
    }

    default:
      return state;
  }
}

export const userDataFeature = 'userData';

/** we scope to feature so other optimistic actions do not affect us */
export const optimisticUserDataReducer = optimistic(userDataReducer);

const _selectUserDataOptimisticState =
  createFeatureSelector<OptimisticUserDataState>(userDataFeature);

const _selectUserDataState = createSelector(
  _selectUserDataOptimisticState,
  state => ensureState(state)
);

export const selectUserDataState = createSelector(
  selectApp,
  _selectUserDataState
);

export const selectData = createSelector(
  selectUserDataState,
  state => state.data
);

export const selectRegistrationId = createSelector(
  selectUserDataState,
  state => state.registrationId
);

export const selectFormData = (fields: FormsApi.Field[], repeater: boolean) =>
  createSelector(selectUserDataState, state =>
    userDataToFormData(fields, state.data, repeater)
  );

export const selectFieldSlugNameMappings = createSelector(
  selectUserDataState,
  state => state.fieldSlugNameMappings
);

export const selectFieldNameSlugMappings = createSelector(
  selectFieldSlugNameMappings,
  slugNameMappings => invert(slugNameMappings)
);

export const selectApplicationGuid = createSelector(
  selectUserDataState,
  state => state.applicationGuid
);

function isLoaded(state: UserDataState, id: string): boolean {
  return state.registrationId === id ? true : false;
}
