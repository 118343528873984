import { HttpErrorResponse } from '@angular/common/http';
import { ApiFile, App451Api, ApplicationsApi } from '@element451-libs/api451';
import { IFieldWithData } from '@element451-libs/forms451';
import { Action } from '@ngrx/store';

export const enum ACCOUNT_ACTIONS {
  SET_AUTH_TOKEN = '[Account] Set Auth Token',
  CLEAR_AUTH_TOKEN = '[Account] Clear Auth Token',

  SIGN_IN_REQUEST = '[Account] Request Sign In',
  SIGN_IN_SUCCESS = '[Account] Success Sign In',
  SIGN_IN_FAIL = '[Account] Fail Sign In',

  SIGN_IN_AFTER_REGISTRATION = '[Account] Sign In After Registration',

  SIGN_OUT = '[Account] Sign Out',

  BASIC_AUTH_LOGIN_SUCCESS = '[Account] Success Basic Auth Login',
  MFA_AUTH_LOGIN_REQUEST = '[Account] Request MFA Login Auth',
  CONFIRM_MFA_LOGIN_CODE = '[Account] Confirm MFA Login Code',
  MFA_AUTH_LOGIN_SUCCESS = '[Account] Success MFA Login Auth',
  MFA_AUTH_LOGIN_FAIL = '[Account] Fail MFA Login Auth',

  LOCKER_URL_LOGIN_REQUEST = '[Account] Request Locker Url Login',
  LOCKER_URL_LOGIN_SUCCESS = '[Account] Success Locker Url Login',
  LOCKER_URL_LOGIN_FAIL = '[Account] Fail Locker Url Login',

  LOCKER_SESSION_LOGIN_REQUEST = '[Account] Request Locker Session Login',
  LOCKER_SESSION_LOGIN_SUCCESS = '[Account] Success Locker Session Login',
  LOCKER_SESSION_LOGIN_FAIL = '[Account] Fail Locker Session Login',

  FORGOT_PASSWORD_REQUEST = '[Account] Request Forgot Password',
  FORGOT_PASSWORD_SUCCESS = '[Account] Success Forgot Password',
  FORGOT_PASSWORD_FAIL = '[Account] Fail Forgot Password',

  RESET_PASSWORD_REQUEST = '[Account] Request Reset Password',
  RESET_PASSWORD_SUCCESS = '[Account] Success Reset Password',
  RESET_PASSWORD_FAIL = '[Account] Fail Reset Password',

  CHECK_PASSWORD_REST_TOKEN_REQUEST = '[Account] Request Check Password Rest Token',
  CHECK_PASSWORD_REST_TOKEN_SUCCESS = '[Account] Success Check Password Rest Token',
  CHECK_PASSWORD_REST_TOKEN_FAIL = '[Account] Fail Check Password Rest Token',

  OPEN_SIGN_IN_DIALOG = '[Account] Open Sign In Dialog',
  OPEN_SIGN_IN_DIALOG_SOCIAL_LOGIN_ERROR = '[Account] Open Sign In Dialog With Social Login Error',

  OPEN_FORGOT_PASSWORD_DIALOG = '[Account] Open Forgot Password Dialog',

  OPEN_CHECK_EMAIL_DIALOG = '[Account] Open Check Email Dialog',

  OPEN_RESET_PASSWORD_DIALOG = '[Account] Open Reset Password Dialog',

  OPEN_RESET_PASSWORD_CONFIRMATION_DIALOG = '[Account] Open Reset Password Confirmation Dialog',

  OPEN_CHANGE_PASSWORD_DIALOG = '[Account] Open Change Password Dialog',

  OPEN_ACCOUNT_EXISTS_REGISTRATION_DIALOG = '[Account] Open Account Exists Registration Dialog',

  OPEN_RATE_LIMIT_ERROR_DIALOG = '[Account] Open Rate Limit Error Dialog',

  VALIDATE_USER_REQUEST = '[Account] Request Validate User',
  VALIDATE_USER_SUCCESS = '[Account] Success Validate User',
  VALIDATE_USER_FAIL = '[Account] Fail Validate User',
  VALIDATE_USER_WITH_NULL_PASSWORD = '[Account] Validate User With Null Password',

  RESEND_VERIFICATION_CODE_REQUEST = '[Account] Request Resend Verification Code',
  RESEND_VERIFICATION_CODE_SUCCESS = '[Account] Success Resend Verification Code',
  RESEND_VERIFICATION_CODE_FAIL = '[Account] Fail Resend Verification Code',

  CONFIRM_USER_WITH_NO_PASSWORD_REQUEST = '[Account] Request Confirm User With No Password',
  CONFIRM_USER_WITH_NO_PASSWORD_SUCCESS = '[Account] Success Confirm User With No Password',
  CONFIRM_USER_WITH_NO_PASSWORD_FAIL = '[Account] Fail Confirm User With No Password',

  REGISTER_USER_REQUEST = '[Account] Request Register User',
  REGISTER_USER_SUCCESS = '[Account] Success Register User',
  REGISTER_USER_FAIL = '[Account] Fail Register User',

  SIGN_IN_UNREGISTERED_STUDENT = '[Account] Sign In Unregistered Student',

  OPEN_APPLICATION_AFTER_LOCKER_LOGIN = '[Account] Open Application After Locker Login',

  ADD_AVATAR = '[Account] Avatar Submitted',

  APPLICATION_ERROR = '[Account] Application Error'
}

/**
 * SET AUTH TOKEN
 */

export class SetAuthTokenAction implements Action {
  readonly type = ACCOUNT_ACTIONS.SET_AUTH_TOKEN;

  constructor(public payload: string) {}
}

export class ClearAuthTokenAction implements Action {
  readonly type = ACCOUNT_ACTIONS.CLEAR_AUTH_TOKEN;
}

/**
 * SIGN IN
 */

export class SignInRequestAction implements Action {
  readonly type = ACCOUNT_ACTIONS.SIGN_IN_REQUEST;
  constructor(public payload: { authentication: string }) {}
}

export class SignInSuccessAction implements Action {
  readonly type = ACCOUNT_ACTIONS.SIGN_IN_SUCCESS;

  constructor(
    public payload: { token: string; data: ApplicationsApi.RequestLocker }
  ) {}
}

export class SignInFailAction implements Action {
  readonly type = ACCOUNT_ACTIONS.SIGN_IN_FAIL;

  constructor(public payload: any) {}
}

/**
 * SIGN IN AFTER REGISTRATION
 */

export class SignInAfterRegistrationAction implements Action {
  readonly type = ACCOUNT_ACTIONS.SIGN_IN_AFTER_REGISTRATION;

  constructor(
    public payload: { registrationId: string; email: string; password?: string }
  ) {}
}

export class BasicAuthLoginSuccessAction implements Action {
  readonly type = ACCOUNT_ACTIONS.BASIC_AUTH_LOGIN_SUCCESS;

  constructor(public payload: string) {}
}

export class MfaAuthLoginRequestAction implements Action {
  readonly type = ACCOUNT_ACTIONS.MFA_AUTH_LOGIN_REQUEST;

  constructor(public payload: { authentication: string }) {}
}

export class ConfirmMfaAuthLoginAction implements Action {
  readonly type = ACCOUNT_ACTIONS.CONFIRM_MFA_LOGIN_CODE;

  constructor(public payload: { code: string }) {}
}
export class MfaAuthLoginSuccessAction implements Action {
  readonly type = ACCOUNT_ACTIONS.MFA_AUTH_LOGIN_SUCCESS;

  constructor(public payload: string) {}
}

export class MfaAuthLoginFailAction implements Action {
  readonly type = ACCOUNT_ACTIONS.MFA_AUTH_LOGIN_FAIL;

  constructor(public payload: HttpErrorResponse) {}
}

/**
 * LOCKER URL LOGIN
 */

export class LockerUrlLoginRequestAction implements Action {
  readonly type = ACCOUNT_ACTIONS.LOCKER_URL_LOGIN_REQUEST;

  constructor(public payload: string) {}
}

export class LockerUrlLoginSuccessAction implements Action {
  readonly type = ACCOUNT_ACTIONS.LOCKER_URL_LOGIN_SUCCESS;

  constructor(
    public payload: { token: string; data: ApplicationsApi.RequestLocker }
  ) {}
}

export class LockerUrlLoginFailAction implements Action {
  readonly type = ACCOUNT_ACTIONS.LOCKER_URL_LOGIN_FAIL;

  constructor(public payload: any) {}
}

/**
 * LOCKER SESSION LOGIN
 */

export class LockerSessionLoginRequestAction implements Action {
  readonly type = ACCOUNT_ACTIONS.LOCKER_SESSION_LOGIN_REQUEST;

  constructor(public payload: string) {}
}

export class LockerSessionLoginSuccessAction implements Action {
  readonly type = ACCOUNT_ACTIONS.LOCKER_SESSION_LOGIN_SUCCESS;

  constructor(
    public payload: { token: string; data: ApplicationsApi.RequestLocker }
  ) {}
}

export class LockerSessionLoginFailAction implements Action {
  readonly type = ACCOUNT_ACTIONS.LOCKER_SESSION_LOGIN_FAIL;

  constructor(public payload: any) {}
}

/**
 * SIGN OUT
 */

export class SignOutAction implements Action {
  readonly type = ACCOUNT_ACTIONS.SIGN_OUT;
}

/**
 * FORGOT PASSWORD
 */

export class ForgotPasswordRequestAction implements Action {
  readonly type = ACCOUNT_ACTIONS.FORGOT_PASSWORD_REQUEST;

  constructor(public payload: { email: string; callback: string }) {}
}

export class ForgotPasswordSuccessAction implements Action {
  readonly type = ACCOUNT_ACTIONS.FORGOT_PASSWORD_SUCCESS;

  constructor(public payload: { email: string }) {}
}

export class ForgotPasswordFailAction implements Action {
  readonly type = ACCOUNT_ACTIONS.FORGOT_PASSWORD_FAIL;

  constructor(public payload: { email: string; error: any }) {}
}

/**
 * REST PASSWORD
 */

export class ResetPasswordRequestAction implements Action {
  readonly type = ACCOUNT_ACTIONS.RESET_PASSWORD_REQUEST;

  constructor(public payload: { password: string; callback: string }) {}
}

export class ResetPasswordSuccessAction implements Action {
  readonly type = ACCOUNT_ACTIONS.RESET_PASSWORD_SUCCESS;
}

export class ResetPasswordFailAction implements Action {
  readonly type = ACCOUNT_ACTIONS.RESET_PASSWORD_FAIL;

  constructor(public payload: any) {}
}

/**
 * CHECK PASSWORD REST TOKEN
 */

export class CheckPasswordResetTokenRequestAction implements Action {
  readonly type = ACCOUNT_ACTIONS.CHECK_PASSWORD_REST_TOKEN_REQUEST;

  constructor(public payload: string) {}
}

export class CheckPasswordResetTokenSuccessAction implements Action {
  readonly type = ACCOUNT_ACTIONS.CHECK_PASSWORD_REST_TOKEN_SUCCESS;
}

export class CheckPasswordResetTokenFailAction implements Action {
  readonly type = ACCOUNT_ACTIONS.CHECK_PASSWORD_REST_TOKEN_FAIL;

  constructor(public payload: any) {}
}

/**
 * OPEN SIGN IN DIALOG
 */

export class OpenSignInDialogAction implements Action {
  readonly type = ACCOUNT_ACTIONS.OPEN_SIGN_IN_DIALOG;

  constructor(public payload: { email: string; password?: string }) {}
}

/**
 * OPEN SIGN IN DIALOG WITH SOCIAL LOGIN ERROR
 */

export class OpenSignInDialogWithSocialLoginErrorAction implements Action {
  readonly type = ACCOUNT_ACTIONS.OPEN_SIGN_IN_DIALOG_SOCIAL_LOGIN_ERROR;

  constructor(public payload: { socialLoginError: string }) {}
}

/**
 * OPEN FORGOT PASSWORD DIALOG
 */

export class OpenForgotPasswordDialogAction implements Action {
  readonly type = ACCOUNT_ACTIONS.OPEN_FORGOT_PASSWORD_DIALOG;

  constructor(public payload: string = null) {}
}

/**
 * OPEN CHECK EMAIL DIALOG
 */

export class OpenCheckEmailDialogAction implements Action {
  readonly type = ACCOUNT_ACTIONS.OPEN_CHECK_EMAIL_DIALOG;

  constructor(public payload: { email: string }) {}
}

/**
 * OPEN RESET PASSWORD  DIALOG
 */

export class OpenResetPasswordDialogAction implements Action {
  readonly type = ACCOUNT_ACTIONS.OPEN_RESET_PASSWORD_DIALOG;

  constructor(public payload: string) {}
}

/**
 * OPEN RESET PASSWORD CONFIRMATION DIALOG
 */

export class OpenResetPasswordConfirmationDialogAction implements Action {
  readonly type = ACCOUNT_ACTIONS.OPEN_RESET_PASSWORD_CONFIRMATION_DIALOG;
}

/**
 *
 */

export class OpenChangePasswordDialogAction implements Action {
  readonly type = ACCOUNT_ACTIONS.OPEN_CHANGE_PASSWORD_DIALOG;
}

/**
 * OPEN ACCOUNT EXISTS REGISTRATION DIALOG
 */

export class OpenAccountExistsRegistrationDialogAction implements Action {
  readonly type = ACCOUNT_ACTIONS.OPEN_ACCOUNT_EXISTS_REGISTRATION_DIALOG;

  constructor(
    public payload: App451Api.ApplicationGuidRegistrationFormGuidAndUserRegisterItem
  ) {}
}

/**
 * VALIDATE USER
 */

export class ValidateUserRequestAction implements Action {
  readonly type = ACCOUNT_ACTIONS.VALIDATE_USER_REQUEST;

  constructor(
    public payload: App451Api.ApplicationGuidRegistrationFormGuidAndUserRegisterItem
  ) {}
}

export class ValidateUserSuccessAction implements Action {
  readonly type = ACCOUNT_ACTIONS.VALIDATE_USER_SUCCESS;

  constructor(
    public payload: App451Api.ApplicationGuidRegistrationFormGuidAndUserRegisterItem
  ) {}
}

export class ValidateUserFailAction implements Action {
  readonly type = ACCOUNT_ACTIONS.VALIDATE_USER_FAIL;

  constructor(
    public payload: App451Api.ApplicationGuidRegistrationFormGuidAndUserRegisterItem
  ) {}
}

export class ValidateUserWithNullPasswordAction implements Action {
  readonly type = ACCOUNT_ACTIONS.VALIDATE_USER_WITH_NULL_PASSWORD;

  constructor(public payload: App451Api.ValidateUserWithNullPassword) {}
}

/**
 * RESEND VERIFICATION CODE
 */
export class ResendVerificationCodeRequestAction implements Action {
  readonly type = ACCOUNT_ACTIONS.RESEND_VERIFICATION_CODE_REQUEST;

  constructor(public payload: { email: string }) {}
}

export class ResendVerificationCodeSuccessAction implements Action {
  readonly type = ACCOUNT_ACTIONS.RESEND_VERIFICATION_CODE_SUCCESS;
}

export class ResendVerificationCodeFailAction implements Action {
  readonly type = ACCOUNT_ACTIONS.RESEND_VERIFICATION_CODE_FAIL;

  constructor(public payload: HttpErrorResponse) {}
}

/**
 * CONFIRM USER WITH NO PASSWORD
 */
export class ConfirmUserWithNoPasswordRequestAction implements Action {
  readonly type = ACCOUNT_ACTIONS.CONFIRM_USER_WITH_NO_PASSWORD_REQUEST;

  constructor(
    public payload: {
      item: App451Api.ApplicationGuidRegistrationFormGuidAndUserRegisterItem;
      code: string;
    }
  ) {}
}

export class ConfirmUserWithNoPasswordSuccessAction implements Action {
  readonly type = ACCOUNT_ACTIONS.CONFIRM_USER_WITH_NO_PASSWORD_SUCCESS;

  constructor(public payload: { token: string; errorMessage?: string }) {}
}

export class ConfirmUserWithNoPasswordFailAction implements Action {
  readonly type = ACCOUNT_ACTIONS.CONFIRM_USER_WITH_NO_PASSWORD_FAIL;

  constructor(public payload: { error: any }) {}
}

/**
 * REGISTER USER
 */

export class RegisterUserRequestAction implements Action {
  readonly type = ACCOUNT_ACTIONS.REGISTER_USER_REQUEST;

  constructor(
    public payload: App451Api.ApplicationGuidRegistrationFormGuidAndUserRegisterItem
  ) {}
}

export class RegisterUserSuccessAction implements Action {
  readonly type = ACCOUNT_ACTIONS.REGISTER_USER_SUCCESS;

  constructor(
    public payload: App451Api.ApplicationGuidRegistrationFormGuidAndUserRegisterItem
  ) {}
}

export class RegisterUserFailAction implements Action {
  readonly type = ACCOUNT_ACTIONS.REGISTER_USER_FAIL;

  constructor(
    public payload: {
      errors: string[];
      application: App451Api.App451Application;
      formData?: IFieldWithData[];
    }
  ) {}
}

/**
 * SIGN IN UNREGISTERED STUDENT
 */

export class SignInUnregisteredStudentAction implements Action {
  readonly type = ACCOUNT_ACTIONS.SIGN_IN_UNREGISTERED_STUDENT;

  constructor(public payload: ApplicationsApi.LoginInfo) {}
}

/**
 * OPEN APPLICATION AFTER LOCKER LOGIN
 */

export class OpenApplicationAfterLockerLoginAction implements Action {
  readonly type = ACCOUNT_ACTIONS.OPEN_APPLICATION_AFTER_LOCKER_LOGIN;

  constructor(public payload: ApplicationsApi.LoginInfo) {}
}

/**
 * ADD AVATAR
 */

export class AddAvatarAction implements Action {
  readonly type = ACCOUNT_ACTIONS.ADD_AVATAR;

  constructor(public payload: ApiFile) {}
}

/**
 * APPLICATION ERROR
 */
export class ApplicationError implements Action {
  readonly type = ACCOUNT_ACTIONS.APPLICATION_ERROR;

  constructor(public payload: string) {}
}

/**
 * RATE LIMIT ERROR DIALOG
 */
export class OpenRateLimitErrorDialogAction implements Action {
  readonly type = ACCOUNT_ACTIONS.OPEN_RATE_LIMIT_ERROR_DIALOG;
}

export type AccountAction =
  | SetAuthTokenAction
  | ClearAuthTokenAction
  | SignInRequestAction
  | SignInSuccessAction
  | SignInFailAction
  | SignInAfterRegistrationAction
  | LockerUrlLoginRequestAction
  | LockerUrlLoginSuccessAction
  | LockerUrlLoginFailAction
  | LockerSessionLoginRequestAction
  | LockerSessionLoginSuccessAction
  | LockerSessionLoginFailAction
  | SignOutAction
  | ForgotPasswordRequestAction
  | ForgotPasswordSuccessAction
  | ForgotPasswordFailAction
  | ResetPasswordRequestAction
  | ResetPasswordSuccessAction
  | ResetPasswordFailAction
  | CheckPasswordResetTokenRequestAction
  | CheckPasswordResetTokenRequestAction
  | CheckPasswordResetTokenRequestAction
  | OpenSignInDialogAction
  | OpenSignInDialogWithSocialLoginErrorAction
  | OpenForgotPasswordDialogAction
  | OpenCheckEmailDialogAction
  | OpenResetPasswordDialogAction
  | OpenResetPasswordConfirmationDialogAction
  | OpenChangePasswordDialogAction
  | OpenRateLimitErrorDialogAction
  | RegisterUserRequestAction
  | RegisterUserSuccessAction
  | RegisterUserFailAction
  | SignInUnregisteredStudentAction
  | OpenApplicationAfterLockerLoginAction
  | AddAvatarAction
  | BasicAuthLoginSuccessAction
  | MfaAuthLoginRequestAction
  | ConfirmMfaAuthLoginAction
  | MfaAuthLoginSuccessAction
  | MfaAuthLoginFailAction
  | ValidateUserWithNullPasswordAction
  | ValidateUserRequestAction
  | ResendVerificationCodeRequestAction
  | ResendVerificationCodeSuccessAction
  | ResendVerificationCodeFailAction
  | ConfirmUserWithNoPasswordRequestAction
  | ConfirmUserWithNoPasswordSuccessAction
  | ConfirmUserWithNoPasswordFailAction
  | ApplicationError;
