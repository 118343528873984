import { Conversations451Api } from '../conversations451';
import { SegmentsApi } from '../segments';

export interface StudentAssistant {
  guid: string;
  name: string | null;
  logo: string | null;
  greeting: string | null;
  title: string | null;
  assistant_type: StudentAssistantType;
  assistant_tools: Conversations451Api.ChatbotTools[];
  description: string | null;
  internal_description?: string | null;
  config: StudentAssistantConfig | null;
  enabled: boolean;
  created_at: string;
  updated_at: string;
  assistant_subtype?: StudentAssistantSubtype[] | null;
  voice?: string | null;
  voice_style?: string | null;
}

export type DefaultStudentAssistant = Omit<
  StudentAssistant,
  'assistant_type' | 'assistant_tools' | 'title'
> & {
  name: string;
  assistant_type: 'default';
};

export interface StudentAssistantConfig {
  limit?: {
    knowledge?: {
      guids?: string[];
      categories?: Conversations451Api.KnowledgeBaseCategories[];
      url_pattern?: string[];
      title_pattern?: string[];
    };
    events?: {
      guids?: string[];
      categories?: string[];
    };
    applications?: {
      guids?: string[];
    };
    appointments?: {
      guids?: string[];
      categories?: string[];
    };
  };
}

export enum StudentAssistantConfigResource {
  Knowledge = 'knowledge.guids',
  Applications = 'applications.guids',
  Events = 'events.guids',
  EventCategories = 'events.categories',
  Appointments = 'appointments.guids',
  AppointmentCategories = 'appointments.categories'
}

export type CreateStudentAssistantDto = Pick<
  StudentAssistant,
  | 'name'
  | 'title'
  | 'assistant_type'
  | 'assistant_tools'
  | 'logo'
  | 'enabled'
  | 'description'
  | 'internal_description'
  | 'greeting'
  | 'config'
  | 'assistant_subtype'
  | 'voice'
  | 'voice_style'
>;

export type UpdateStudentAssistantDto = Partial<
  Pick<
    StudentAssistant,
    | 'name'
    | 'title'
    | 'assistant_type'
    | 'assistant_tools'
    | 'logo'
    | 'enabled'
    | 'description'
    | 'internal_description'
    | 'greeting'
    | 'config'
    | 'assistant_subtype'
    | 'voice'
    | 'voice_style'
  >
>;

export enum StudentAssistantType {
  AcademicAdvisor = 'academic-advisor',
  CareerAdvisor = 'career-advisor',
  FinancialAidAdvisor = 'financial-aid-advisor',
  CommonAppAdvisor = 'common-app-advisor',
  AdmissionAdvisor = 'admission-advisor',
  CampusLifeAdvisor = 'campus-life-advisor',
  PeerAdvisor = 'peer-advisor',
  AlumniAdvisor = 'alumni-advisor',
  AthleticsAdvisor = 'athletics-advisor'
}

export const STUDENT_ASSISTANT_TYPE_LABELS: Record<
  StudentAssistantType | 'default',
  string
> = {
  [StudentAssistantType.AcademicAdvisor]: 'Academic Advisor',
  [StudentAssistantType.CareerAdvisor]: 'Career Advisor',
  [StudentAssistantType.FinancialAidAdvisor]: 'Financial Aid Advisor',
  [StudentAssistantType.CommonAppAdvisor]: 'Common App Advisor',
  [StudentAssistantType.AdmissionAdvisor]: 'Admission Advisor',
  [StudentAssistantType.CampusLifeAdvisor]: 'Campus Life Advisor',
  [StudentAssistantType.PeerAdvisor]: 'Peer Advisor',
  [StudentAssistantType.AlumniAdvisor]: 'Alumni Advisor',
  [StudentAssistantType.AthleticsAdvisor]: 'Athletics Advisor',
  default: 'Default'
};

export const STUDENT_ASSISTANT_TYPE_DEFAULT_TOOLS: Record<
  StudentAssistantType,
  Conversations451Api.ChatbotTools[]
> = {
  [StudentAssistantType.AcademicAdvisor]: [
    Conversations451Api.ChatbotTools.ScheduleAppointment,
    Conversations451Api.ChatbotTools.ProgramExplorer,
    Conversations451Api.ChatbotTools.CourseCatalog,
    Conversations451Api.ChatbotTools.TeamMember
  ],
  [StudentAssistantType.CareerAdvisor]: [
    Conversations451Api.ChatbotTools.TeamMember,
    Conversations451Api.ChatbotTools.ProgramExplorer,
    Conversations451Api.ChatbotTools.CourseCatalog
  ],
  [StudentAssistantType.FinancialAidAdvisor]: [
    Conversations451Api.ChatbotTools.TeamMember,
    Conversations451Api.ChatbotTools.FinancialAidKnowledgeBase,
    Conversations451Api.ChatbotTools.ScheduleAppointment
  ],
  [StudentAssistantType.CommonAppAdvisor]: [
    Conversations451Api.ChatbotTools.TeamMember,
    Conversations451Api.ChatbotTools.CommonAppKnowledgeBase
  ],
  [StudentAssistantType.AdmissionAdvisor]: [
    Conversations451Api.ChatbotTools.ApplicationStatusChecklist,
    Conversations451Api.ChatbotTools.StartApplication,
    Conversations451Api.ChatbotTools.ScheduleAppointment,
    Conversations451Api.ChatbotTools.RegisterForEvent,
    Conversations451Api.ChatbotTools.GetApplicationStatus,
    Conversations451Api.ChatbotTools.TeamMember
  ],
  [StudentAssistantType.CampusLifeAdvisor]: [
    Conversations451Api.ChatbotTools.ScheduleAppointment,
    Conversations451Api.ChatbotTools.RegisterForEvent,
    Conversations451Api.ChatbotTools.TeamMember
  ],
  [StudentAssistantType.PeerAdvisor]: [
    Conversations451Api.ChatbotTools.RegisterForEvent,
    Conversations451Api.ChatbotTools.TeamMember
  ],
  [StudentAssistantType.AlumniAdvisor]: [
    Conversations451Api.ChatbotTools.ScheduleAppointment,
    Conversations451Api.ChatbotTools.TeamMember
  ],
  [StudentAssistantType.AthleticsAdvisor]: [
    Conversations451Api.ChatbotTools.ScheduleAppointment,
    Conversations451Api.ChatbotTools.RegisterForEvent,
    Conversations451Api.ChatbotTools.TeamMember
  ]
};

export type StudentAssistantSubtype = AdmissionsAssistantSubtype;

export enum AdmissionsAssistantSubtype {
  Undergraduate = 'undegraduate',
  Graduate = 'graduate',
  ContinuingEducation = 'continuing-education',
  International = 'international',
  Domestic = 'domestic'
}

export const ADMISSIONS_ASSISTANT_SUBTYPE_LABELS: Record<
  AdmissionsAssistantSubtype,
  string
> = {
  [AdmissionsAssistantSubtype.Undergraduate]: 'Undergraduate',
  [AdmissionsAssistantSubtype.Graduate]: 'Graduate',
  [AdmissionsAssistantSubtype.ContinuingEducation]: 'Continuing Education',
  [AdmissionsAssistantSubtype.International]: 'International',
  [AdmissionsAssistantSubtype.Domestic]: 'Domestic'
};

export interface StudentAssistantsGroup {
  guid: string;
  name: string;
  enabled: boolean;
  assistants: string[];
  conditions: SegmentsApi.SegmentValue3 | null;
  index_weight: number;
  created_at: string;
  updated_at: string;
}

export type CreateStudentAssistantsGroupDto = Pick<
  StudentAssistantsGroup,
  'name' | 'assistants' | 'conditions' | 'enabled'
>;

export type UpdateStudentAssistantsGroupDto = Partial<
  Pick<StudentAssistantsGroup, 'name' | 'assistants' | 'conditions' | 'enabled'>
>;

export const getStudentAssistantName = (
  assistant:
    | Pick<StudentAssistant, 'name' | 'assistant_type'>
    | undefined
    | null,
  prefix?: string
) => {
  if (!assistant) return 'N/A';

  const label = STUDENT_ASSISTANT_TYPE_LABELS[assistant.assistant_type];
  const type = prefix ? `${prefix} ${label}` : label;

  return assistant.name || type || 'N/A';
};

export const formatAssistantHandoffToolName = (
  assistant:
    | Pick<StudentAssistant, 'name' | 'assistant_type' | 'assistant_subtype'>
    | undefined
    | null
) => {
  const assistantName = assistant.name
    ? assistant.name
        .toLowerCase()
        .replace(/ /g, '-')
        .replace(/[^a-zA-Z0-9_-]/g, '')
    : '';

  const handoffSuffix = 'handoff';

  let toolName = [assistant.assistant_type, assistantName, handoffSuffix]
    .filter(Boolean)
    .join('-');

  if (toolName.length <= 64) {
    return toolName;
  }

  // if name is too long we will use only assistant type
  toolName = [assistant.assistant_type, handoffSuffix].join('-');
  return toolName;
};

export enum AvatarType {
  Cartoonish = 'cartoonish',
  Realistic = 'realistic'
}

export interface GenerateAvatarDto {
  type: AvatarType;
  instructions?: string;
  appearance: string;
}

export const createDefaultStudentAssistant = (
  chatbotSettings: Conversations451Api.ConversationsChatbotSettingsDto
): DefaultStudentAssistant => ({
  guid: 'default',
  assistant_type: 'default',
  name: chatbotSettings.chatbot_name,
  logo: chatbotSettings.chatbot_avatar,
  greeting: chatbotSettings.chatbot_greeting,
  voice: chatbotSettings.chatbot_voice,
  voice_style: chatbotSettings.chatbot_voice_style,
  description: null,
  internal_description: null,
  config: null,
  enabled: true,
  created_at: '',
  updated_at: ''
});

export const isDefaultStudentAssistant = (
  assistant:
    | Omit<StudentAssistant, 'created_at' | 'updated_at'>
    | DefaultStudentAssistant
    | { guid: string }
): assistant is DefaultStudentAssistant => assistant.guid === 'default';
