import {
  WebhooksApi_GetWebhookExecutionsQuery as GetWebhookExecutions,
  WebhooksApi_GetWebhookExecutionsQueryVariables as GetWebhookExecutionsVariables
} from '@element451-libs/graphql-comet/client';
import { gql } from 'apollo-angular';

export { GetWebhookExecutions, GetWebhookExecutionsVariables };

export const getWebhookExecutions = gql`
  query WebhooksApi_GetWebhookExecutions($input: WebhooksExecutionsInput!) {
    webhooksExecutions(input: $input) {
      items {
        _id
        status
        webhook_guid
        payload
        execute_at
        try_count
        try_results
        related_to
        updated_at
        created_at
      }
      pagination {
        from_execution_id
      }
      total
    }
  }
`;
